import React, { PropsWithChildren } from 'react'
import { ListItem, ListItemLabel } from 'baseui/list'
import { useStyletron, styled } from 'baseui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { ourColors } from './Colors'
import { Button } from './Button'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'
import { Theme } from 'baseui/theme'

const getListNavOverrides = (url?: string) => {
  const listNavStyles = {
    textDecoration: 'none',
    marginTop: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    width: '100%',
    maxWidth: '100%',
    fontColor: ourColors.copyGrey,
    color: ourColors.copyGrey,
    backgroundColor: ourColors.white,
    ':link': {
      color: ourColors.copyGrey
    },
    ':visited': {
      color: ourColors.copyGrey
    },
    ':hover': {
      color: ourColors.copyGrey
    },
    ':active': {
      color: ourColors.copyGrey
    }
  }

  if (url) {
    return {
      BaseButton: {
        component: styled<{}, React.ComponentClass<GatsbyLinkProps<{}>, {}>, Theme>(
          GatsbyLink,
          () => listNavStyles
        ),
        props: {
          to: url
        }
      }
    }
  } else {
    return {
      BaseButton: {
        style: listNavStyles
      }
    }
  }
}

interface ListNavItemProps {
  backgroundColor?: string
  withoutLine?: boolean
  description?: string
  testId?: string
  url?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any
}
export const ListNavItem = ({
  onClick,
  url,
  backgroundColor,
  withoutLine,
  description,
  children,
  testId
}: PropsWithChildren<ListNavItemProps>) => {
  const [css] = useStyletron()
  return (
    <li
      className={css({
        marginBottom: 0
      })}
    >
      <Button onClick={onClick} data-testid={testId} overrides={getListNavOverrides(url)}>
        <Grid
          gridMargins={[0, 0]}
          gridGutters={[0, 0]}
          overrides={{ Grid: { style: { width: '100%', marginLeft: '0px', marginRight: '0px' } } }}
        >
          <Cell span={[4, 6]} align={ALIGNMENT.start}>
            <ListItem
              endEnhancer={() => (
                <div
                  className={css({
                    fontSize: '22px'
                  })}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              )}
              overrides={{
                Content: {
                  style: {
                    borderBottomWidth: withoutLine || description ? 0 : '1px',
                    borderBottomColor: ourColors.interfaceGrey,
                    marginLeft: 0,
                    width: '100%',
                    paddingRight: 0,
                    height: '59px',
                    backgroundColor: backgroundColor || ourColors.white
                  }
                },
                Root: {
                  component: styled<{}, 'div'>('div', ({ $theme }) => {
                    return {
                      alignItems: 'center',
                      backgroundColor: $theme.colors.backgroundPrimary,
                      display: 'flex',
                      listStyleType: 'none',
                      width: '100%'
                    }
                  })
                }
              }}
            >
              <ListItemLabel>
                <div
                  className={css({
                    fontSize: '18px',
                    fontFamily: 'Roboto',
                    width: '100%'
                  })}
                >
                  {children}
                </div>
              </ListItemLabel>
            </ListItem>
          </Cell>
          <Cell span={[4, 6]} align={ALIGNMENT.start}>
            {description && <DescriptionExtension description={description} />}
          </Cell>
        </Grid>
      </Button>
    </li>
  )
}

interface DescriptionExtension {
  description: string
}
const DescriptionExtension = ({ description }: DescriptionExtension) => {
  const [css] = useStyletron()
  return (
    <div
      className={css({
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: ourColors.interfaceGrey,
        color: ourColors.hoverGrey,
        backgroundColor: ourColors.white,
        fontSize: '16px',
        paddingBottom: '20px',
        textAlign: 'start'
      })}
    >
      {description}
    </div>
  )
}

interface ListNavProps {
  children: React.ReactNode
}
export const ListNav = ({ children }: ListNavProps) => {
  const [css] = useStyletron()

  return (
    <ul
      className={css({
        marginLeft: 0,
        marginBottom: 0,
        listStyleType: 'none'
      })}
    >
      {children}
    </ul>
  )
}
