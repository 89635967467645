import { Issue } from './api/cms'
import { LoginResponse } from './api/user'
import { navigate } from 'gatsby'

export function getQueryVariable(targetVar: string, query: string) {
  if (query[0] === '?') {
    query = query.substring(1)
  }
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === targetVar) {
      // Take everything in the list after the first element and join it together.
      // This allows us to preserve log in redirects like redirect=/issues?id=1
      return decodeURIComponent(pair.slice(1).join('='))
    }
  }
}

export function slugify(str: string) {
  return str
    .replace(/[^\w\s-!?]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .toLowerCase()
}

export function getDateFromDateTime(date: Date) {
  const newDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`
  return newDate
}

export function leadingZero(num: number) {
  return ('0' + num.toString()).slice(-2)
}

export function formatTime(time: string | null) {
  if (time) {
    const [hours, minutes] = time.split(':').map(number => parseInt(number))
    const suffix = hours / 12 >= 1 ? 'pm' : 'am'
    return leadingZero(hours % 12) + ':' + leadingZero(minutes) + suffix
  }
  return ''
}

export function formatDate(strDate: string) {
  // add 1 to the month because it's 0 based.
  const date = new Date(strDate)
  return (
    leadingZero(date.getDate()) + '/' + leadingZero(date.getMonth() + 1) + '/' + date.getFullYear()
  )
}

export function getIssueDateString(issue: Issue) {
  let timeString = formatDate(issue.start_date)
  if (issue.ongoing) {
    timeString += ' - ongoing'
  } else if (issue.end_date) {
    timeString += ` - ${formatDate(issue.end_date)}`
  }
  if (issue.start_time) {
    timeString = `${formatTime(issue.start_time)} ${timeString}`
  }
  return timeString
}

function generateFullPath() {
  const path = window.location.pathname
  if (path.substring(path.length - 1, path.length) === '/') {
    return `${path.substring(0, path.length - 1)}${window.location.search}`
  } else {
    return `${path}${window.location.search}`
  }
}

export function loginRedirect() {
  const path = generateFullPath()
  if (!path.startsWith('/login')) {
    navigate(`/login?redirect=${path}`)
  }
}

export function handleErrorFromAPI(
  error: any,
  setUserContext: ((loginResponse: LoginResponse | null, rememberMe: boolean) => void) | null,
  setErrorState?: (status: boolean) => void
) {
  if (error.response && error.response.status === 401) {
    window.localStorage.removeItem('user')
    window.sessionStorage.removeItem('user')
    if (setUserContext) {
      setUserContext(null, false)
    }
    loginRedirect()
  } else {
    if (setErrorState) {
      setErrorState(true)
    }
  }
}
