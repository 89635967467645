import { Layout, UserContext } from '../components/Layout'
import React from 'react'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { Grid, Cell } from 'baseui/layout-grid'
import { useStyletron } from 'baseui'
import { ListNavItem, ListNav } from '../components/ListNav'
import {
  HazardPagesListResponse,
  LiteHazardPagesResponseItem,
  searchHazards,
  LiteToolsPagesResponseItem,
  ToolsPagesListResponse,
  searchGuides
} from '../api/cms'
import { ourColors } from '../components/Colors'
import { HorizontalLine } from '../components/Line'
import { HeadingLarge, ParagraphLarge } from 'baseui/typography'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { getQueryVariable, slugify, handleErrorFromAPI, loginRedirect } from '../utils'

interface Result {
  label: string
  url: string
}

interface SearchResultBoxProps {
  title: string
  results: Result[] | null
  error: boolean
}
export const SearchResultBox = ({ title, results, error }: SearchResultBoxProps) => {
  const [css] = useStyletron()
  let resultsElement = null

  if (results) {
    resultsElement = (
      <>
        <HorizontalLine
          className={css({
            marginBottom: 0
          })}
        />
        <ListNav>
          {results.map(result => (
            <ListNavItem
              url={result.url}
              key={result.url}
              backgroundColor={ourColors.lightGrey}
              testId={slugify(result.label)}
            >
              {result.label}
            </ListNavItem>
          ))}
        </ListNav>
      </>
    )
  } else {
    resultsElement = (
      <ParagraphLarge
        overrides={{
          Block: {
            style: {
              marginBottom: 0
            }
          }
        }}
      >
        {error
          ? 'There was an error loading search results, try again later.'
          : 'No results found.'}
      </ParagraphLarge>
    )
  }

  return (
    <div
      className={css({
        backgroundColor: ourColors.lightGrey,
        paddingLeft: '18px',
        paddingRight: '18px',
        paddingTop: '16px',
        paddingBottom: '28px'
      })}
      data-testid={`resultbox:${title}`}
    >
      <HeadingLarge
        overrides={{
          Block: {
            style: {
              marginTop: 0,
              marginBottom: '18px'
            }
          }
        }}
      >
        {title}
      </HeadingLarge>
      {resultsElement}
    </div>
  )
}

interface GuideSearchResultsProps {
  searchQuery: string
}
export const GuideSearchResults = ({ searchQuery }: GuideSearchResultsProps) => {
  const [guides, setGuides] = React.useState<LiteToolsPagesResponseItem[] | null>(null)
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const { token, setUserContext } = React.useContext(UserContext)

  React.useEffect(() => {
    if (token && searchQuery) {
      searchGuides(token, searchQuery)
        .then((response: ToolsPagesListResponse) => {
          if (response.meta.total_count > 0) {
            setGuides(response.items.slice(0, 3))
          } else {
            setGuides(null)
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, searchQuery, setUserContext])

  const listItems = guides
    ? guides.map(guide => ({
        label: guide.title,
        url: `/tools?id=${guide.id}`
      }))
    : null

  return (
    <SearchResultBox title={'In Tools - Guides'} results={listItems} error={loadingErrorActive} />
  )
}

interface HazardSearchResultsProps {
  searchQuery: string
}
export const HazardSearchResults = ({ searchQuery }: HazardSearchResultsProps) => {
  const [hazards, setHazards] = React.useState<LiteHazardPagesResponseItem[] | null>(null)
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const { token, role, setUserContext } = React.useContext(UserContext)

  React.useEffect(() => {
    if (token && searchQuery && role) {
      searchHazards(token, searchQuery, role)
        .then((response: HazardPagesListResponse) => {
          if (response.meta.total_count > 0) {
            setHazards(response.items.slice(0, 3))
          } else {
            setHazards(null)
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, searchQuery, role, setUserContext])

  const listItems = hazards
    ? hazards.map(hazard => ({
        label: hazard.title,
        url: `/hazard?id=${hazard.id}`
      }))
    : null

  return <SearchResultBox title={'In hazards'} results={listItems} error={loadingErrorActive} />
}

interface SearchResultsPageProps {
  location: WindowLocation
}
export const SearchResultsPage = (props: SearchResultsPageProps) => {
  const { token } = React.useContext(UserContext)
  const searchQuery = getQueryVariable('search', props.location.search)
  const [css] = useStyletron()

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }
    if (!searchQuery) {
      navigate('/404', { replace: true })
    }
  }, [token, searchQuery])

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <HeadingLevel>
            <Heading>Search results for “{searchQuery}”</Heading>
          </HeadingLevel>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          {searchQuery && <HazardSearchResults searchQuery={searchQuery} />}
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <div
            className={css({
              marginTop: '29px'
            })}
          >
            {searchQuery && <GuideSearchResults searchQuery={searchQuery} />}
          </div>
        </Cell>
      </Grid>
    </>
  )
}

interface LayoutSearchPageProps {
  location: WindowLocation
}
const LayoutSearchResultsPage = (props: LayoutSearchPageProps) => {
  return (
    <Layout searchBarOpen={true}>
      <SearchResultsPage location={props.location} />
    </Layout>
  )
}

export default LayoutSearchResultsPage
